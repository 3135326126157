.common-modal-container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-width: 1100px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  -webkit-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0;
  pointer-events: none;
}

.common-modal-container.active {
  opacity: 1;
  pointer-events: all;
}

.common-modal-container * {
  box-sizing: border-box;
}

.common-modal-inner {
  position: relative;
  max-height: calc(100vh - 80px);
}

.common-modal {
  max-height: calc(100vh - 80px);
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  overflow: auto;
}

.common-modal-closer {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  display: block;
  background: transparent;
  padding: 0;
  width: 50px;
  height: 50px;
  border: solid 1px #00005C;
  border-radius: 50%;
  position: absolute;
  top: -25px;
  right: -60px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.common-modal-closer::before,
.common-modal-closer::after {
  content: "";
  display: block;
  background-color: #00005C;
  width: 55%;
  height: 1px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.common-modal-closer::before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.common-modal-closer::after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.common-modal-closer:hover {
  background-color: #00005C;
}

.common-modal-closer:hover::before,
.common-modal-closer:hover::after {
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .common-modal-container {
    min-width: 280px;
    padding-top: var(--size-100);
  }
  .common-modal-container._search .common-modal-inner {
    max-width: 100%;
  }
  .search-modal-accordion-container._area {
    margin-left: -3.84615vw;
    width: 89.7435897436vw;
  }
  .common-modal-inner {
    max-width: calc(100% - 40px);
    max-width: calc(100% - 10.25641vw);
    max-height: calc(100vh - 140px);
    max-height: calc(100vh - 35.89743vw);
    width: 100%;
  }
  .common-modal {
    max-height: calc(100vh - 140px);
    max-height: 80vh;
    padding: 20px;
    padding: 5.1282vw;
  }
  .common-modal-closer {
    width: 50px;
    width: 12.82051vw;
    height: 50px;
    height: 12.82051vw;
    top: -60px;
    top: -15.38461vw;
    right: 0;
  }
  .common-modal-closer._search {
    right: var(--size-20);
  }
}
.search-modal-datetime-group {
  position: relative;
}

.ui-datepicker-div_wrap {
  position: absolute;
  width: 100%;
  top: 36px;
}

@media screen and (max-width: 767px) {
  .ui-datepicker-div_wrap {
    width: 100%;
    top: 9.2307692308vw;
  }
  .ui-datepicker-div_wrap .ui-datepicker table {
    width: 100%;
    font-size: 3.5897435897vw;
  }
}
@media screen and (max-width: 767px) {
  .ui-datepicker-div_wrap .ui-datepicker .ui-datepicker-next, .ui-datepicker-div_wrap .ui-datepicker .ui-datepicker-prev {
    width: 7.6923076923vw !important;
  }
}
.ui-datepicker-div_wrap._modal #ui-datepicker-div {
  width: 342px;
}

@media screen and (max-width: 767px) {
  .ui-datepicker-div_wrap._modal #ui-datepicker-div {
    width: 100%;
  }
}
.ui-datepicker-div_wrap #ui-datepicker-div {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
}

@media screen and (max-width: 767px) {
  .ui-datepicker-div_wrap #ui-datepicker-div {
    width: auto;
  }
}
@media screen and (max-width: 767px) {
  .ui-datepicker-div_wrap .ui-state-default {
    width: auto !important;
  }
}
@media screen and (max-width: 767px) {
  #input-group-day-time .ui-datepicker-div_wrap #ui-datepicker-div {
    width: auto;
  }
}